import React, { useState, useEffect } from "react";
import { heroImages } from "../constants/images"; // Importera bilderna
import { useNavigate } from "react-router-dom";

function Hero() {
  const [currentImage, setCurrentImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Slumpa en bild vid laddning
    const randomImage = heroImages[Math.floor(Math.random() * heroImages.length)];
    setCurrentImage(randomImage);
  }, []);

  if (!currentImage) return null; // Vänta tills en bild är vald

  return (
    <div
      style={{
        backgroundImage: `url(${currentImage.url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "400px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "20px", borderRadius: "5px" }}>
        <h1 style={{ fontSize: "2.5em", fontWeight: "bold", color: "#fff" }}>
          {currentImage.alt}
        </h1>
        <button
          onClick={() => navigate("/hitta-stipendium")}
          style={{
            marginTop: "10px",
            padding: "10px 20px",
            fontSize: "1em",
            color: "#000",
            backgroundColor: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Explore Now
        </button>
      </div>
    </div>
  );
}

export default Hero;
