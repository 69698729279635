import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";
import { texts } from "../constants/texts";

function Header() {
  const { language, toggleLanguage } = useLanguage();

  return (
    <header className="header">
      <div className="header-logo">
        <Link to="/">{texts.header.logo[language]}</Link>
      </div>
      <nav className="header-nav">
        <Link to="/hitta-stipendium">{texts.header.links.findScholarship[language]}</Link>
        <Link to="/om-oss">{texts.header.links.aboutUs[language]}</Link>
        <Link to="/kontakta-oss">{texts.header.links.contactUs[language]}</Link>
      </nav>
      <button onClick={toggleLanguage}>
        {language === "sv" ? "Switch to English" : "Byt till svenska"}
      </button>
    </header>
  );
}

export default Header;
