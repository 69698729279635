import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ScholarshipList from "../components/ScholarshipList";
import { useLanguage } from "../contexts/LanguageContext";
import { texts } from "../constants/texts";

function ScholarshipPage() {
  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("http://localhost:3002/api/scholarships")
      .then((response) => {
        if (!response.ok) throw new Error(`API-fel: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        const headers = data[0];
        const scholarships = data.slice(1).map((row) => {
          return headers.reduce((acc, key, index) => {
            acc[key] = row[index];
            return acc;
          }, {});
        });
        setData(scholarships);
        setLoading(false);
      })
      .catch((error) => {
        setError("Kunde inte hämta stipendier. Försök igen senare.");
        setLoading(false);
      });
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <h1>{texts.header.links.findScholarship[language]}</h1>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : error ? (
        <div style={{ color: "red", textAlign: "center" }}>{error}</div>
      ) : (
        <ScholarshipList data={data} />
      )}
    </div>
  );
}

export default ScholarshipPage;
