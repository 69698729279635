import React from "react";
import InfoSection from "../components/InfoSection";
import { texts } from "../constants/texts";
import { useLanguage } from "../contexts/LanguageContext";

function AboutPage() {
    const { language } = useLanguage();

    return (
        <div>
            <InfoSection
                title={texts.info.about.title[language]}
                content={texts.info.about.content[language]}
            />
        </div>
    );
}

export default AboutPage;
