import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header from './components/Header.js';
import Home from './pages/HomePage.js';
import ScholarshipPage from './pages/ScholarshipPage.js';
import AboutPage from './pages/AboutPage.js';
import ContactPage from './pages/ContactPage.js';
import './styles/App.css'; // Importera CSS
import { LanguageProvider } from './contexts/LanguageContext.js'; // Importera kontexten

const theme = createTheme();

function App() {
  return (
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <Router>
          {/* Header */}
          <Header />

          {/* Routes */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/hitta-stipendium" element={<ScholarshipPage />} />
            <Route path="/om-oss" element={<AboutPage />} />
            <Route path="/kontakta-oss" element={<ContactPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
