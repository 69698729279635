import React from 'react';

function InfoSection({ title, content }) {
  return (
    <section style={styles.section}>
      <h2 style={styles.title}>{title}</h2>
      <p style={styles.content}>{content}</p>
    </section>
  );
}

const styles = {
  section: { padding: '50px 20px', textAlign: 'center' },
  title: { fontSize: '1.8em', fontWeight: 'bold', marginBottom: '10px' },
  content: { fontSize: '1em', color: '#333' },
};

export default InfoSection;
