// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css'; // Grundläggande reset och typsnitt
import './styles/global.css'; // Appspecifika stilar
import App from './App';
import reportWebVitals from './reportWebVitals';

// Skapa root-elementet för React-applikationen
const root = ReactDOM.createRoot(document.getElementById('root'));

// Rendera App-komponenten inuti StrictMode för att aktivera extra kontroller och varningar
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Om du vill mäta prestanda, lägg till en funktion för att logga resultat
// eller skicka data till ett analysverktyg
reportWebVitals();
