import React, { useState, useEffect } from 'react';
import { Typography, Box, List, ListItem, ListItemText, Collapse, Autocomplete, TextField, Grid, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function ScholarshipList({ data, language = "sv" }) {
  const [expandedId, setExpandedId] = useState(null);
  const [filteredScholarships, setFilteredScholarships] = useState(data);
  const [filters, setFilters] = useState({
    category: '',
    country: '',
    education_req: '',
    age: '',
  });

  const categories = [...new Set(data.map(scholarship => scholarship.scholarship_category))];
  const countries = [...new Set(data.map(scholarship => scholarship.scholarship_country))];
  const educationLevels = [...new Set(data.map(scholarship => scholarship.education_req))];

  const handleExpandClick = (index) => {
    setExpandedId(expandedId === index ? null : index);
  };

  const handleFilterChange = (event, value, name) => {
    setFilters({
      ...filters,
      [name]: value || '',
    });
  };

  useEffect(() => {
    const age = parseInt(filters.age, 10);
    setFilteredScholarships(
      data.filter((scholarship) => {
        const categoryMatch = !filters.category || scholarship.scholarship_category === filters.category;
        const countryMatch = !filters.country || scholarship.scholarship_country === filters.country;
        const educationMatch = !filters.education_req || scholarship.education_req === filters.education_req;
        const ageMatch = !filters.age || (
          (!isNaN(age) && (!scholarship.min_age || age >= scholarship.min_age) && (!scholarship.max_age || age <= scholarship.max_age))
        );

        return categoryMatch && countryMatch && educationMatch && ageMatch;
      })
    );
  }, [filters, data]);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>Stipendielista</Typography>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={categories}
            getOptionLabel={(option) => option || ''}
            value={filters.category}
            onChange={(event, value) => handleFilterChange(event, value, 'category')}
            renderInput={(params) => <TextField {...params} label="Kategori" variant="outlined" fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={countries}
            getOptionLabel={(option) => option || ''}
            value={filters.country}
            onChange={(event, value) => handleFilterChange(event, value, 'country')}
            renderInput={(params) => <TextField {...params} label="Land" variant="outlined" fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={educationLevels}
            getOptionLabel={(option) => option || ''}
            value={filters.education_req}
            onChange={(event, value) => handleFilterChange(event, value, 'education_req')}
            renderInput={(params) => <TextField {...params} label="Utbildningskrav" variant="outlined" fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Ålder"
            type="number"
            variant="outlined"
            value={filters.age}
            onChange={(event) => setFilters({ ...filters, age: event.target.value })}
            fullWidth
          />
        </Grid>
      </Grid>

      <List>
        {filteredScholarships.map((scholarship, index) => (
          <div key={index}>
            <ListItem button onClick={() => handleExpandClick(index)} sx={{ borderBottom: '1px solid #ddd' }}>
              <ListItemText primary={scholarship.scholarship_name} />
              <IconButton onClick={() => handleExpandClick(index)}>
                {expandedId === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </ListItem>
            <Collapse in={expandedId === index} timeout="auto" unmountOnExit>
              <Box sx={{ paddingLeft: 4, paddingY: 2, backgroundColor: '#f9f9f9', borderLeft: '4px solid #ddd' }}>
                {Object.keys(scholarship).map((key) => (
                  <Typography key={key} variant="body2" sx={{ marginBottom: 0.5 }}>
                    <strong>{key.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())}:</strong> {scholarship[key] || "Data saknas"}
                  </Typography>
                ))}
              </Box>
            </Collapse>
          </div>
        ))}
      </List>
    </Box>
  );
}

export default ScholarshipList;
