import React, { createContext, useState, useContext, useEffect } from "react";

// Skapa kontexten
const LanguageContext = createContext();

// Kontextens provider
export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState("sv");

  // Ladda språket från localStorage när applikationen startar
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "sv";
    setLanguage(savedLanguage);
  }, []);

  // Uppdatera språk och spara i localStorage
  const toggleLanguage = () => {
    setLanguage((prev) => {
      const newLanguage = prev === "sv" ? "en" : "sv";
      localStorage.setItem("language", newLanguage);
      return newLanguage;
    });
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

// Anpassad hook för att använda kontexten
export function useLanguage() {
  return useContext(LanguageContext);
}
