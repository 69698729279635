import React from "react";
import InfoSection from "../components/InfoSection";
import { texts } from "../constants/texts";
import { useLanguage } from "../contexts/LanguageContext";

function ContactPage() {
  const { language } = useLanguage();

  return (
    <div>
      <InfoSection
        title={texts.info.contact.title[language]}
        content={texts.info.contact.content[language]}
      />
    </div>
  );
}

export default ContactPage;
