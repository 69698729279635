export const texts = {
    hero: {
        home: {
            title: {
                sv: "Välkommen till Stipendio",
                en: "Welcome to Stipendio",
            },
            subtitle: {
                sv: "Hitta rätt stöd för dina studier.",
                en: "Find the right support for your studies.",
            },
        },
        about: {
            title: {
                sv: "Om Stipendio",
                en: "About Stipendio",
            },
            subtitle: {
                sv: "Lär dig mer om vår plattform.",
                en: "Learn more about our platform.",
            },
        },
        contact: {
            title: {
                sv: "Kontakta oss",
                en: "Contact Us",
            },
            subtitle: {
                sv: "Vi hjälper dig gärna!",
                en: "We're happy to help!",
            },
        },
    },
    info: {
        about: {
            title: {
                sv: "Om Stipendio",
                en: "About Stipendio",
            },
            content: {
                sv: "Stipendio är en plattform som hjälper studenter att hitta stipendier.",
                en: "Stipendio is a platform that helps students find scholarships.",
            },
        },
        contact: {
            title: {
                sv: "Kontakta oss",
                en: "Contact Us",
            },
            content: {
                sv: "För frågor, vänligen kontakta oss på info@stipendio.com.",
                en: "For inquiries, please contact us at info@stipendio.com.",
            },
        },
    },
    header: {
        logo: {
            sv: "Stipendio",
            en: "Stipendio",
        },
        links: {
            findScholarship: {
                sv: "Hitta stipendium",
                en: "Find Scholarship",
            },
            aboutUs: {
                sv: "Om oss",
                en: "About Us",
            },
            contactUs: {
                sv: "Kontakta oss",
                en: "Contact Us",
            },
        },
    },
};
